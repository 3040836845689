<template>
	<div class="page">
		<div v-if="$hasAccess('adverts.adlist/index')" @click="openRoute('market-ad-list')">广告位管理</div>
		<div v-if="$hasAccess('market.zone/index')" @click="openRoute('market-zone-list')">活动专区</div>
		<div v-if="$hasAccess('market.jpush/index')" @click="openRoute('market-jpush-list')">极光推送</div>
	</div>
</template>

<script>
	import Tables from '@/components/tables'
	export default {
		name: 'market-actionPush',
		components: {
			Tables
		},
		data() {
			return {
			}
		},
		computed: {
		},
		mounted() {
		},
		activated() {},
		methods: {
			openRoute(url){
				this.$router.push({
					name: url,
				});
			}
		}
	}
</script>

<style lang="scss" scoped>
.page{
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-content: flex-start;
	div{
		text-align: center;
		width: 180px;
		height: 60px;
		line-height: 60px;
		background: #eee;
		margin: 10px;
	}
}
</style>
